import styled from 'styled-components';
import PropTypes from 'prop-types';
import variants from '../../utils/theme';

const color = variants('colors', 'variant', {
  primary: 'main',
  secondary: 'white',
  link: 'main',
});
const bgColor = variants('colors', 'variant', {
  primary: 'white',
  secondary: 'main',
  link: 'white',
});

const borderColor = variants('colors', 'variant', {
  primary: 'main',
  secondary: 'main',
  link: 'white',
});

const Button = styled.button`
padding: 8px 16px;

/* Color the border and text with theme.main */
color: ${color};
border: 2px solid ${borderColor};
background-color: ${bgColor};
border-radius: 28px;
cursor:pointer;
outline:none;

`;

export default Button;

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'link']),
};

Button.defaultProps = {
  variant: 'primary',
};
