import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./layout/Header";
import Container from "../components/Containter";
import Footer from "./layout/Footer";
import styled from "styled-components";
const Home = lazy(() => import("./main/Home/Home"));
const Detail = lazy(() => import("./main/Detail"));

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;
// para que no se pise con lo demas elementos debe tener el tamaño del footer + el margin
const FixFooter = styled.div`
padding-bottom:225px ;
`

const WaitingComponent = (Component) => (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component {...props} />
  </Suspense>
);

const Pages = () => (
  <Router>
    <PageContainer>
      <Header />
      <Switch>
        <Container>
          <Route exact path="/" component={WaitingComponent(Home)} />
          <Route
            exact
            path="/detail/:id"
            component={WaitingComponent(Detail)}
          />
        </Container>
      </Switch>
      <FixFooter/>
      <Footer />
    </PageContainer>
  </Router>
);

export default Pages;
