import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@import url(${(props) => props.theme.font.url});

*{
    padding:0;
    margin:0;
    box-sizing:border-box
}

body{
    font-family:${(props) => props.theme.font.family};
    font-weight:400;
    font-size:15px;
    background-color:#ffffff;
}

h1{
    font-weight:400;
    font-size:36px
}
h2{
    font-weight:400;
    font-size:30px
}
h3{
    font-weight:400;
    font-size:26px
}
h4{
    font-weight:600;
    font-size:20px
}
h5{
    font-weight:600;
    font-size:18px
}
h6{
    font-weight:600;
    font-size:16px
}

a{
    text-decoration:none;
}




`;

export default GlobalStyle;
