import React, { lazy, Suspense, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Buttons";
import Container from "../../../components/Containter";
import { CgMenuLeft } from "react-icons/cg";
import { FiXCircle } from "react-icons/fi";

const Logo = lazy(() => import("../../../config/Config"));

const HeaderStyled = styled.header`
  width: 100%;
  height: 130px;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(114, 109, 128, 0.05);
  margin-bottom: 54px;
  display:flex;
  align-items: center;
`;


const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 20px 0 0;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.main};
  }

  @media (min-width: ${(props) => props.theme.media.sm}) {
    display: none;
  }
`;


const ConatinerHeader = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

const Icon = styled(CgMenuLeft)`
  cursor: pointer;
  font-size: 32px;
  @media (min-width: ${(props) => props.theme.media.sm}) {
    display: none;
  }
`;

const ButtonHeader = styled(Button)`
  width: 125px;
  margin: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightMenu = styled.div`
  position: fixed;
  width: 280px;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: #ffffff;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;

  .headerButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: ${(props) => props.theme.media.sm}) {
    position: static;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(0);
    .headerButtons {
      flex-direction: row;
    }
  }
`;

const Header = () => {
  const [open, setOpen] = useState(false);
 
  return (
    <HeaderStyled>
      <ConatinerHeader>
        <Suspense fallback={<p>loading...</p>}>
          <Logo />
        </Suspense>
        <Icon onClick={() => setOpen(true)} />
        <RightMenu open={open}>
        <CloseIcon>
          <FiXCircle onClick={() => setOpen(false)} />
        </CloseIcon>
          <div className="headerButtons">
            <ButtonHeader
              as="a"
              href={process.env.REACT_APP_TL_SITE}
              target="_blank"
              rel="noopener noreferrer"
              variant="link"
            >
              Sitio institucional
            </ButtonHeader>
            <ButtonHeader
              as="a"
              href="https://app.fonselp.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login
            </ButtonHeader>
            <ButtonHeader
              as="a"
              href={`https://app.fonselp.com/${process.env.REACT_APP_TL_TENANT}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="secondary"
            >
              Registrate
            </ButtonHeader>
          </div>
        </RightMenu>
      </ConatinerHeader>
    </HeaderStyled>
  );
};

export default Header;

/* 
  <div className="headerButtons">
        <ButtonHeader
          as="a"
          href="https://www.mayma.org.ar/"
          target="_blank"
          rel="noopener noreferrer"
          variant="link"
        >
          Sitio institucional
        </ButtonHeader>
        <ButtonHeader
          as="a"
          href="https://app.fonselp.com/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Login
        </ButtonHeader>
        <ButtonHeader
          as="a"
          href="https://app.fonselp.com/mayma"
          target="_blank"
          rel="noopener noreferrer"
          variant="secondary"
        >
          Registrate
        </ButtonHeader>
      </div>


*/
