import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './theme/globalStyles';
import Pages from './pages';

function App() {
  const [theme, setTheme] = useState({module:{},loaded:false});
 
  useEffect(() => {
    const importTheme = async() =>{
      const module = await import("./theme/theme")
      setTheme({module:module.default,loaded:true})
    } 

    importTheme()
  }, []);
  if(!theme.loaded) return <div>Cargando</div>

  
  return (
    <ThemeProvider theme={theme.module}>
      <GlobalStyle theme={theme.module} />
      <Pages></Pages>
    </ThemeProvider>
  );
}

export default App;
