import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${(props) => props.theme.media.sm}) {
    max-width: 540px;
  }
  @media (min-width: ${(props) => props.theme.media.md}) {
    max-width: 720px;
  }
  @media (min-width: ${(props) => props.theme.media.xl}) {
    max-width: 960px;
  }
  @media (min-width: ${(props) => props.theme.media.xxl}) {
    max-width: 1200px;
  }
`;

export default Container;
