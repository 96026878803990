import React, { lazy, Suspense } from 'react'
import styled from 'styled-components';
const Logo = lazy(() => import("../../../config/Config"));

const FooterStyled = styled.footer`
  width: 100%;
  height: 175px;
  background-color: ${({theme})=>theme.colors.bgLigth};
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Footer = () => {
    return (
        <FooterStyled>
        <Suspense fallback={<p>loading...</p>}>
          <Logo />
        </Suspense>
        </FooterStyled>
    )
}

export default Footer;
